<template>
  <div class="home">
    <div class="demo-image__lazy">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电4G-MF1/images/长电迷你_07.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "长电4G-MF1",
  components: {
    elMenu, foot, navigation
  },
  data() {
    return {
      urls: [
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
        'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
      ]
    }
  }
}
</script>

<style scoped>
.demo-image__lazy .el-image {
  /*display: block;*/
  /*min-height: 200px;*/
  /*margin-bottom: 10px;*/
}
</style>